<template>
	<div>
		<a-modal v-model:visible="visible" width="800px" @cancel="close">
			<template #title>
				<modalHeader title="下账申请"></modalHeader>
			</template>
			<template #footer>
				<a-button @click="close">取消</a-button>
				<a-button @click="onSubmit" type="primary">确认</a-button>
			</template>
			
			<a-form ref="formRef" :model="formModal" :label-col="{ span: 6 }">
				<a-row>
					<a-col :span="13">
						<a-form-item name="assetsId" label="盘亏资产">
							{{formModal.name}}
						</a-form-item>
						
						
						<a-form-item name="cause" label="原因">
							{{formModal.cause === 1?'盘亏':''}}
							<!-- <a-input v-model:value="formModal.cause" placeholder="盘亏" disabled></a-input> -->
						</a-form-item>
						
						<a-form-item name="voucherNo" label="会计凭证号">
							{{formModal.voucherNo}}
						</a-form-item>
						
						<a-form-item label="补充说明">
							{{formModal.explain}}
						</a-form-item>
						
						<a-form-item name="fileList" label="申请资料">
							<a :href="formModal.materialUrl">查看材料</a>
						</a-form-item>
					</a-col>
					<a-col :span="11">
						<div class="ui-box">
							<a-form-item label="资产编号" class="ui-result__item" :label-col="{ span: 9 }">
								{{showFormModal.code}}
							</a-form-item>
							<a-form-item label="资产分类名称" class="ui-result__item" :label-col="{ span: 9 }">
								{{showFormModal.classifyName}}
							</a-form-item>
							<a-form-item label="资产名称" class="ui-result__item" :label-col="{ span: 9 }">
								{{showFormModal.name}}
							</a-form-item>
							<a-form-item label="取得方式" class="ui-result__item" :label-col="{ span: 9 }">
								<!-- {{showFormModal.gainType}} -->
								<div v-if="showFormModal.gainType === 1">新购</div>
								<div v-if="showFormModal.gainType === 2">调拨</div>
								<div v-if="showFormModal.gainType === 3">接受捐赠</div>
								<div v-if="showFormModal.gainType === 4">自建</div>
								<div v-if="showFormModal.gainType === 5">置换</div>
								<div v-if="showFormModal.gainType === 6">盘盈</div>
								<div v-if="showFormModal.gainType === 7">自行研制</div>
								<div v-if="showFormModal.gainType === 8">其他</div>
							</a-form-item>
							<a-form-item label="取得日期" class="ui-result__item" :label-col="{ span: 9 }">
								{{transDateTime(showFormModal.gainTime)}}
							</a-form-item>
							<a-form-item label="设备用途" class="ui-result__item" :label-col="{ span: 9 }">
								<!-- {{showFormModal.useType}} -->
								<div v-if="showFormModal.useType === 1">办公</div>
								<div v-if="showFormModal.useType === 2">教学</div>
								<div v-if="showFormModal.useType === 3">培训</div>
								<div v-if="showFormModal.useType === 4">执法</div>
								<div v-if="showFormModal.useType === 5">窗口</div>
								<div v-if="showFormModal.useType === 6">专用</div>
								<div v-if="showFormModal.useType === 7">其他</div>
								<div v-if="showFormModal.useType === 8">医疗</div>
								<div v-if="showFormModal.useType === 9">科研</div>
							</a-form-item>
							<a-form-item label="规格型号" class="ui-result__item" :label-col="{ span: 9 }">
								{{showFormModal.model}}
							</a-form-item>
							<a-form-item label="价值(元)" class="ui-result__item" :label-col="{ span: 9 }">
								{{showFormModal.amount}}
							</a-form-item>
							<a-form-item label="资产照片" class="ui-result__item" :label-col="{ span: 9 }">
								<!-- {{showFormModal.imgUrl}} -->
								<a-image :width="100" v-for="(item,index) in showFormModal.imgList" :key="index"  :src="item"></a-image>
							</a-form-item>
							<a-form-item label="使用部门" class="ui-result__item" :label-col="{ span: 9 }">
								{{showFormModal.departmentName}}
							</a-form-item>
							<a-form-item label="使用人" class="ui-result__item" :label-col="{ span: 9 }">
								{{showFormModal.user}}
							</a-form-item>
						</div>
					</a-col>
				</a-row>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
	// import { getAssetsDetailSearch } from '@/service/modules/property.js';
	import { lossOrProfitDetail, postAudit } from '@/service/modules/inventory.js';
	import filesUpload from '@/components/upload/filesUpload.vue';
	import modalHeader from '@/components/modalHeader/index.vue';
	// import assetsSelect from '@/components/assetsSelect/index.vue';
	export default {
		components: { filesUpload, modalHeader },
		data() {
			return {
				visible: false,
				fixCause: '盘亏',
				assetsIds: [],
				formModal: {
					fileList: [],
					assetsId: []
				},
				showFormModal: {
				},
				fileList: []
			}
		},
		methods: {
			async getData(id) {
				this.loading = true;
				try {
					let ret = await lossOrProfitDetail({
						id
					});
					this.loading = false;
					if (ret.code === 200) {
						// this.assetsId = ret.data.assetsId,
						this.formModal.name = ret.data.assets.name,
						this.formModal.cause = ret.data.cause,
						this.formModal.voucherNo = ret.data.voucherNo,
						this.formModal.explain = ret.data.explain,
						this.formModal.materialUrl = ret.data.materialUrl,
						this.showFormModal = ret.data.assets
						this.showFormModal.imgList = []
						let tempStr = ret.data.assets.imgUrl
						let tempArr = tempStr? tempStr.split(','): []
						this.showFormModal.imgList.push(...tempArr)
						// this.imgList = ret.data.assets.imgUrl.split(',')
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async onSubmit() {
					this.loading = true;
					try {
						let ret = await postAudit({
							id: this.assetsId,
						});
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('确认成功');
							this.$emit('refresh');
							this.close();
						}
					} catch(e) {
						this.loading = false;
					}
			},
			open(e) {
				if (e && e.skipId) { // 从待办跳转
					this.getData(e.skipId)
				} else if(e && e.id){
					this.assetsId = e.id
					this.getData(e.id)
				}
				this.visible = true;
			},
			close() {
				this.$refs.formRef.clearValidate();
				this.formModal = {}
				this.showFormModal = {
					imgList: []
				}
				this.assetsId = null
				this.visible = false;
			},
		}
	}
</script>

<style scoped>
	.ui-box {
		width: 300px;
		padding: 10px;
		margin: 0 0 0 auto;
		background: #F5F7FA;
		border-radius: 10px;
	}
	.ui-result__item {
		margin-bottom: 0;
	}
</style>